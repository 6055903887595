
import {
  defineComponent,
  reactive,
  ref,
  toRefs,
  onMounted,
  nextTick,
} from 'vue'
import { digitUppercase } from '@/utils/index'
import { detailApi } from './api'
import {
  feeType,
  state,
  reason,
  classesType,
  billStatus,
  payChannel,
  rentType,
  workEndStatus,
  chargeType,
  contractBillType,
  overdueStatus,
  moneyType,
  Week,
  getTableConfig2,
} from './config'
import dayjs from 'dayjs'
import CTable from '@/components/c-table'
export default defineComponent({
  props: {
    visible: Boolean,
    rowData: Object,
  },
  components: { CTable },
  setup(props, { emit }) {
    const data = reactive({
      dialogVisible: props.visible,
      form: ref<any>({}),
      formRef: null,
      tableConfig: getTableConfig2(),
      istable: true,
    })

    // 枚举
    const Enum = {
      feeType,
      state,
      reason,
      billStatus,
      rentType,
      payChannel,
      chargeType,
      workEndStatus,
      contractBillType,
      moneyType,
      classesType,
      overdueStatus,
    }

    const handleClose = () => {
      emit('update:visible', false)
    }

    const weekTranstionCN = (str) => {
      const arr = str.split(',')
      const t = arr.map((i) => Week[i])
      return t.join()
    }

    onMounted(() => {
      detailApi({ uuid: props.rowData.uuid }).then((res) => {
        data.form = res.data || {}
        data.istable = false
        data.tableConfig = getTableConfig2(res.data.feeType)
        nextTick(() => {
          data.tableConfig.data = res.data.detailCarList || []
          data.istable = true
        })
      })
    })

    return {
      ...toRefs(data),
      ...Enum,
      digitUppercase,
      dayjs,
      weekTranstionCN,
      handleClose,
    }
  },
})
