/*
 * @Description:
 * @Author: yyx
 * @@后台人员: xxx
 * @Date: 2022-05-19 11:42:20
 * @LastEditors: yyx
 * @LastEditTime: 2022-05-24 17:19:41
 */
import service from '@/utils/request'

// 列表
export const getPageOfEnterprise = (params: any) =>
  service.request({
    method: 'post',
    url: '/enterprise/deduction/page',
    data: params
  })

// 查询汇总
export const sumApi = (params: any) =>
  service.request({
    method: 'post',
    url: '/enterprise/deduction/sum',
    data: params
  })

// 详情
export const detailApi = (params: any) =>
  service.request({
    method: 'post',
    url: '/enterprise/deduction/newDetail',
    data: params
  })
