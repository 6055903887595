
import CSearchList from '@/components/c-search-list'
import CTable from '@/components/c-table'
import { getIndexConfig, getTableConfig } from './config'
import { getPageOfEnterprise, sumApi } from './api'
import CButtonGroup from '@/components/c-button-group'
import detail from './detail.vue'
import { btnName } from '@/utils/index'
import { defineComponent, toRefs, reactive, onMounted, ref } from 'vue'
import dayjs from 'dayjs'
export default defineComponent({
  components: {
    CSearchList,
    CTable,
    detail,
    CButtonGroup
  },
  setup() {
    const data = reactive({
      ...getIndexConfig(),
      fileTitle: '扣费记录' + dayjs().format('YYYY-MM-DD'),
      sumDate: ref<any>({})
    })
    // 表单组件变量
    const tableConfig = reactive({
      ...getTableConfig()
    })

    /**
     * 查询
     */
    const getListFn = () => {
      const obj = Object.assign(
        {},
        { ...data.queryForm },
        {
          currPage: tableConfig.pagination.currentPage,
          pageSize: tableConfig.pagination.pageSize,
          deductTimeStart:
            (data.queryForm as any).dateTimeArea instanceof Array
              ? dayjs((data.queryForm as any).dateTimeArea[0]).format(
                'YYYY-MM-DD HH:mm:ss'
              )
              : '',
          deductTimeEnd:
            (data.queryForm as any).dateTimeArea instanceof Array
              ? dayjs((data.queryForm as any).dateTimeArea[1]).format(
                'YYYY-MM-DD 23:59:59'
              )
              : '',
          createTimeStart:
            (data.queryForm as any).dateTimeArea2 instanceof Array
              ? dayjs((data.queryForm as any).dateTimeArea2[0]).format(
                'YYYY-MM-DD HH:mm:ss'
              )
              : '',
          createTimeEnd:
            (data.queryForm as any).dateTimeArea2 instanceof Array
              ? dayjs((data.queryForm as any).dateTimeArea2[1]).format(
                'YYYY-MM-DD  23:59:59'
              )
              : ''
        }
      )
      getPageOfEnterprise(obj).then(res => {
        tableConfig.data = res.data.list
        tableConfig.pagination.total = res.data.total
      })

      sumApi(obj).then(res => {
        console.log(3333, res)
        data.sumDate = res.data
      })
    }
    /**
     * 重置
     */
    const handleReset = val => {
      data.queryForm = val
      getListFn()
    }
    const handleSearch = (params: object) => {
      data.queryForm = Object.assign({}, params)
      getListFn()
    }

    const pageCurrentChange = (val: number) => {
      tableConfig.pagination.currentPage = val
      getListFn()
    }
    const pageSizeChange = (val: number) => {
      tableConfig.pagination.currentPage = 1
      tableConfig.pagination.pageSize = val
      getListFn()
    }

    // 查看详情
    const look = (params?: any) => {
      data.visible = true
      data.rowData = params
      console.log(params)
    }

    onMounted(() => {
      handleSearch({
        dateTimeArea2: [(new Date() as any) - 3600 * 1000 * 24 * 30, new Date()]
      })
    })
    return {
      handleReset,
      handleSearch,
      pageCurrentChange,
      pageSizeChange,
      tableConfig,
      btnName,
      look,
      getListFn,
      ...toRefs(data)
    }
  }
})
