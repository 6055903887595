import { enumToOption } from '@/utils'
import { h } from 'vue'
import { ElTag } from 'element-plus'

// 费用项目
export enum feeType {
  车辆使用费 = 1,
  // 基础平台费,
  增值服务费 = 3,
  技术服务费 = 4
}

// 状态
export enum state {
  扣费成功 = 1,
  扣费失败,
  // 基础费用,
  // 收费费用
}

// 原因说明
export enum reason {
  逾期支付 = 1,
  余额不足,
  基础车辆,
  收费车辆,
  代扣账单
}

// 是否逾期
export enum overdueStatus {
  逾期 = 1
}

export enum Week {
  周一 = 1,
  周二,
  周三,
  周四,
  周五,
  周六,
  周日
}

// 账单状态
export enum billStatus {
  未收 = 1,
  已收,
  未付,
  已付,
  已作废
}

// 收付渠道
export enum payChannel {
  人工转账 = 1,
  押金抵扣 = 3,
  支付宝 = 101,
  微信 = 102,
  工商银行 = 103,
}

// 租车类型（
export enum rentType {
  承包制 = 1,
  轮班制
}

// 类型
export enum workEndStatus {
  当天 = 1,
  次日
}

// 收租类型
export enum chargeType {
  按月 = 1,
  按周期,
  按天
}

// 收租类型
export enum contractBillType {
  '每天生成（全年无休模式）' = 1,
  '每周减免天数',
  '每月减免天数'
}

// 付款方式
export enum moneyType {
  预付 = 1,
  后付,
  每月减免天数
}

// 负责班次
export enum classesType {
  白班 = 1,
  夜班,
  通班
}

export const getTableConfig = () => {
  // table 通用属性
  const common = {
    // table 有多选时设置
    isSelection: false,
    // table loading
    loading: false,
    // table 是否为斑马纹
    stripe: true,
    isIndex: false,
    // table 索引 label
    indexLabel: '序号',
    // table 的尺寸 medium / small / mini
    size: 'small',
    // table 数据
    data: []
  }

  // table 分页
  const pagination = {
    // 是否展示分页
    isShow: true,
    // 总条数
    total: 0,
    // 每页显示条目个数
    pageSize: 20,
    // 当前页数
    currentPage: 1,
    // 每页显示个数选择器的选项设置
    pageSizes: [10, 20, 40, 80, 100]
  }

  // table 列数据
  const column = [
    {
      key: 'uuid',
      label: '扣费编号',
      width: '140'
    },
    {
      key: 'enterpriseCode',
      label: '企业编号',
      width: '140'
    },
    {
      key: 'companyName',
      label: '企业名称',
      width: '140'
    },
    {
      key: 'feeType',
      label: '费用项目',
      width: '140',
      formatter: (row, col, val) => {
        return feeType[val]
      }
    },
    {
      key: 'price',
      label: '单价(元)',
      width: '120'
    },
    {
      key: 'carNum',
      label: '车辆数',
      width: '100'
    },
    {
      key: 'amount',
      label: '数量',
      width: '100'
    },
    {
      key: 'rate',
      label: '费率(%)',
      width: '100'
    },
    {
      key: 'fee',
      label: '实扣费用(元)',
      width: '120'
    },
    {
      key: 'state',
      label: '状态',
      width: '100',
      formatter: (row, col, val) => {
        return h(ElTag, {
          size: 'medium',
          type: val === 1 ? 'success' : 'danger'
        }, state[val])
      }
    },
    {
      key: 'reason',
      label: '原因',
      width: '100',
      formatter: (row, col, val) => {
        return reason[val]
      }
    },
    {
      key: 'deductTime',
      label: '扣费时间',
      width: '150'
    },
    {
      key: 'action',
      label: '操作',
      width: '100',
      fixed: 'right',
      slot: 'action'
    }
  ]

  return {
    ...common,
    pagination,
    column
  }
}

// 页面搜索和基础变量
export const getIndexConfig = () => {
  // 搜索变量
  const inputList = [
    {
      type: 'input',
      label: '企业编号',
      key: 'enterpriseCode',
      placeholder: '请输入',
      labelWidth: 100,
      inputWidth: 200,
      maxlength: 18,
      rules: {}
    },
    {
      type: 'input',
      label: '企业名称',
      key: 'companyName',
      placeholder: '请输入',
      labelWidth: 100,
      inputWidth: 200,
      maxlength: 32,
      rules: {}
    },
    {
      type: 'datePicker',
      label: '扣费时间',
      key: 'dateTimeArea',
      placeholder: '请选择',
      labelWidth: 100,
      inputWidth: 280,
      format: 'YYYY-MM-DD',
      dateType: 'daterange',
      // 开启日期范围后生效
      unlinkPanels: false,
      rangeSeparator: '-',
      startPlaceholder: '开始日期',
      endPlaceholder: '结束日期',
      rules: {
        // 默认 date 开启范围 array
        type: 'array'
      }
    },
    {
      type: 'input',
      label: '扣费编号',
      key: 'uuid',
      placeholder: '请输入',
      labelWidth: 100,
      inputWidth: 200,
      maxlength: 32,
      rules: {}
    },
    {
      type: 'select',
      label: '费用项目',
      key: 'feeType',
      placeholder: '请选择',
      labelWidth: 100,
      inputWidth: 200,
      options: enumToOption(feeType),
      rules: {
        type: 'number'
      }
    },
    {
      type: 'datePicker',
      label: '创建时间',
      key: 'dateTimeArea2',
      placeholder: '请选择',
      labelWidth: 100,
      inputWidth: 280,
      format: 'YYYY-MM-DD',
      dateType: 'daterange',
      // 开启日期范围后生效
      unlinkPanels: false,
      rangeSeparator: '-',
      startPlaceholder: '开始日期',
      endPlaceholder: '结束日期',
      rules: {
        // 默认 date 开启范围 array
        type: 'array'
      }
    },
    {
      type: 'input',
      label: '车牌号',
      key: 'carNo',
      placeholder: '请输入',
      labelWidth: 100,
      inputWidth: 200,
      maxlength: 8,
      rules: {}
    },
    {
      type: 'select',
      label: '状态',
      key: 'state',
      placeholder: '请选择',
      labelWidth: 100,
      inputWidth: 200,
      options: enumToOption(state),
      rules: {
        type: 'number'
      }
    },
    {
      type: 'select',
      label: '原因说明',
      key: 'reason',
      placeholder: '请选择',
      labelWidth: 100,
      inputWidth: 200,
      options: enumToOption(reason),
      rules: {
        type: 'number'
      }
    },
    {
      type: 'input',
      label: '账单编号',
      key: 'billNo',
      placeholder: '请输入',
      labelWidth: 100,
      inputWidth: 200,
      maxlength: 32,
      rules: {}
    }
  ]

  // 页面基础变量
  const page = {
    queryForm: {}, // 搜索参数
    visible: false, // 弹框显示
    title: '新建员工',
    rowData: null // 单行数据
  }

  return {
    inputList,
    ...page
  }
}
export const getTableConfig2 = (type?) => {
  // table 通用属性
  const common = {
    // table 有多选时设置
    isSelection: false,
    // table loading
    loading: false,
    // table 是否为斑马纹
    stripe: true,
    isIndex: false,
    // table 索引 label
    indexLabel: '序号',
    // table 的尺寸 medium / small / mini
    size: 'small',
    height: 300,
    // table 数据
    data: []
  }

  // table 分页
  const pagination = {
    // 是否展示分页
    isShow: false,
    // 总条数
    total: 0,
    // 每页显示条目个数
    pageSize: 100,
    // 当前页数
    currentPage: 1,
    // 每页显示个数选择器的选项设置
    pageSizes: [10, 20, 40, 80, 100]
  }

  // table 列数据
  const column = [
    {
      key: 'carNo',
      label: '车牌号',
      width: '100'
    },
    {
      key: 'vin',
      label: '车架号',
      width: '120'
    },
    {
      key: 'billingCount',
      label: '计费数量',
      width: '100'
    },
    {
      key: 'createTime',
      label: '创建时间',
      width: '120'
    },
    {
      key: 'controlNo',
      label: '缴费/缴费/合同账单 编号',
      width: '120'
    }
  ]

  console.log(type)
  // if (type === 4) {
  //   column[3].label = '合同账单编号'
  // }

  return {
    ...common,
    pagination,
    column
  }
}
